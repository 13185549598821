@charset "UTF-8";
/**
 * Fontface mixin
 * @param string $weight Font family
 * @param int    $weight Font weight
 * @param type   $style  Font Style
 * @return rule
 */
/**
 * PxGrotesk mixin
 * @param int  $weight Font weight
 * @param type $style  Font Style
 * @return rule
 */
/**
 * Input placeholder styles
 */
/**
 * Last child margin reset
 * @param property $property
 */
/**
 * Creates a set of options (extends the current selector)
 * @param list $combos List example $list : ("selector-added-class" : ( "property" value, "property" value ))
 */
/**
 * Padded content
 */
/**
 * Slick dots overrides
 * @param int  $size    Dots size
 * @param list $color   List containing initial, hover and selected colors
 * @param int  $spacing Space between dots
 */
/**
 * Slick arrows overrides
 * @param int  $size      Arrows size
 * @param list $color     List containing initial, hover and disabled colors
 * @param int  $translate Special adjustment measure (depending on column offset for the slick container)
 */
/**
 * Custom icons
 */
/**
 * Vertical align using position absolute
 */
/**
 * Gradients
 */
/**
 * Properties/projects lists
 */
.list .list-item > .row > div {
  background-color: white; }

.list .list-item > .row {
  position: relative; }
  .list .list-item > .row .badge {
    z-index: 2;
    position: absolute;
    top: 1.4em;
    right: 0;
    padding: 0.5em 1em 0.4em;
    background-color: #ff5100;
    font-family: "PxGrotesk";
    font-weight: 300;
    font-style: normal;
    font-size: 1.1em;
    color: white;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .list .list-item > .row .badge {
        font-size: 0.95em; } }
    @media (min-width: 992px) {
      .list .list-item > .row .badge {
        font-size: 0.85em; } }
    .list .list-item > .row .badge.message {
      top: 0;
      width: 100%;
      text-align: center; }
    @media (min-width: 768px) {
      .list .list-item > .row .badge.multiple {
        right: 3.3333333333%; }
      .list .list-item > .row .badge.message {
        width: 93.3333333334%; } }

.list .list-item .info {
  padding: 0.4em 0; }
  .list .list-item .info h4 {
    margin: 0;
    font-family: "PxGrotesk";
    font-weight: 500;
    font-style: normal;
    font-size: 1.3em; }
    @media (min-width: 768px) {
      .list .list-item .info h4 {
        font-size: 1.1em; } }
    @media (min-width: 992px) {
      .list .list-item .info h4 {
        font-size: 1em; } }
    .list .list-item .info h4 i {
      margin: 0 0.5em;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      .list .list-item .info h4 i:before {
        content: ""; }
    .list .list-item .info h4 span {
      font-weight: 300; }
    .list .list-item .info h4 a {
      text-decoration: none;
      color: #363636; }
  .list .list-item .info p {
    margin-bottom: 0.8em;
    font-family: "PxGrotesk";
    font-weight: 300;
    font-style: normal;
    font-size: 1.1em;
    text-align: left !important;
    line-height: 1.2em; }
    @media (min-width: 768px) {
      .list .list-item .info p {
        font-size: 0.95em; } }
    @media (min-width: 992px) {
      .list .list-item .info p {
        font-size: 0.85em; } }
    .list .list-item .info p.price {
      margin-bottom: 0;
      color: #ff5100;
      font-size: 1.3em; }
      @media (min-width: 768px) {
        .list .list-item .info p.price {
          font-size: 1em; } }
      @media (min-width: 992px) {
        .list .list-item .info p.price {
          font-size: 0.9em; } }
  .list .list-item .info ul {
    list-style: none;
    font-family: "PxGrotesk";
    font-weight: 300;
    font-style: normal;
    font-size: 1.1em; }
    @media (min-width: 768px) {
      .list .list-item .info ul {
        font-size: 0.95em; } }
    @media (min-width: 992px) {
      .list .list-item .info ul {
        font-size: 0.85em; } }
    .list .list-item .info ul li {
      display: inline-block;
      margin-right: 1.2em; }
      .list .list-item .info ul li:last-child {
        margin-right: 0; }
      .list .list-item .info ul li i {
        margin-right: 0.2em;
        display: inline-block;
        font: normal normal normal 14px/1 'CustomIcons';
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-transform: none;
        font-size: 1.2em; }
        .list .list-item .info ul li i.icon-footage:before {
          content: "t"; }
        .list .list-item .info ul li i.icon-bedrooms:before {
          content: "u"; }
        .list .list-item .info ul li i.icon-bathrooms:before {
          content: "v"; }
  .list .list-item .info .tools {
    text-align: right; }
    .list .list-item .info .tools a {
      position: relative;
      width: 1em;
      display: inline-block;
      font: normal normal normal 14px/1 'CustomIcons';
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-transform: none;
      font-size: 1.8em;
      text-indent: -9999px;
      text-decoration: none;
      text-align: left; }
      @media (min-width: 768px) {
        .list .list-item .info .tools a {
          font-size: 1.3em; } }
      @media (min-width: 992px) {
        .list .list-item .info .tools a {
          font-size: 1.1em; } }
      .list .list-item .info .tools a:before {
        position: absolute;
        top: -0.1em;
        left: 0em;
        width: 100%;
        font-size: 0.9em;
        text-indent: 0;
        text-align: center; }
      .list .list-item .info .tools a.fav:before {
        content: "b";
        color: #ff5100; }
      .list .list-item .info .tools a.compare:before {
        content: "e";
        color: #363636; }
  .list .list-item .info [class^="col-"] {
    padding: 0.6em 0.3em; }
    .list .list-item .info [class^="col-"]:last-child {
      border-top: 1px solid #dedddd; }
